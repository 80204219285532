import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./FAQ.css";

const FAQ = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const faqCategories = [
    {
      category: "Général",
      icon: <i className="fas fa-home"></i>,
      questions: [
        {
          question: "Comment créer un compte ?",
          answer:
            "Pour créer un compte, cliquez sur le bouton 'S'inscrire' en haut à droite de la page. Remplissez le formulaire avec vos informations personnelles et suivez les instructions.",
        },
        {
          question: "Comment réinitialiser mon mot de passe ?",
          answer:
            "Cliquez sur 'Mot de passe oublié' sur la page de connexion. Entrez votre adresse e-mail et suivez les instructions envoyées pour réinitialiser votre mot de passe.",
        },
      ],
    },
    {
      category: "Services",
      icon: <i className="fas fa-cogs"></i>,
      questions: [
        {
          question: "Quels sont vos horaires d'ouverture ?",
          answer:
            "Nous sommes ouverts du lundi au vendredi de 8h à 18h. Le service client est disponible 24h/24 pour répondre à toutes vos questions.",
        },
        {
          question: "Proposez-vous des services personnalisés ?",
          answer:
            "Oui, nous proposons des services sur mesure adaptés à vos besoins spécifiques. Contactez notre équipe pour en savoir plus.",
        },
      ],
    },
    {
      category: "Paiement",
      icon: <i className="fas fa-credit-card"></i>,
      questions: [
        {
          question: "Quels moyens de paiement acceptez-vous ?",
          answer:
            "Nous acceptons Orange Money, MTN Mobile Money, Yoomee Money, PayPal et les virements bancaires.",
        },
        {
          question: "Les paiements sont-ils sécurisés ?",
          answer: "Oui, tous nos paiements sont sécurisés.",
        },
      ],
    },
    {
      category: "Support",
      icon: <i className="fas fa-hands-helping"></i>,
      questions: [
        {
          question: "Comment contacter le support ?",
          answer:
            "Vous pouvez nous contacter par email à stelotechconsulting@gmail.com ou par téléphone au (237) 6 20 20 20 20. Nous répondons généralement sous 24h.",
        },
        {
          question: "Avez-vous des Contacts soiaux ?",
          answer:
            "Oui, vous pouvez nous suivre et discutez avec nous ur facebook, X (Stelotech Consulting), telegram et Whatsapp  ",
        },
      ],
    },
  ];

  const filteredFAQ = faqCategories
    .map((category) => ({
      ...category,
      questions: category.questions.filter(
        (q) =>
          q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
          q.answer.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter((category) => category.questions.length > 0);

  return (
    <div className="faq-page">
      <div className="faq-header">
        <Container>
          <Row className="justify-content-center text-center">
            <Col md={8}>
              <h1 className="mb-4">Comment pouvons-nous vous aider ?</h1>
              <InputGroup className="mb-5 search-bar">
                <InputGroup.Text>
                  <i className="fas fa-search"></i>
                </InputGroup.Text>
                <FormControl
                  placeholder="Rechercher une question..."
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </InputGroup>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Titre FAQ */}
      {
        <div className="faq-title-section">
          <Container>
            <Row className="justify-content-center text-center">
              <Col md={8}>
                <h2 className="faq-main-title">Questions Fréquemment Posées</h2>
                <p className="faq-subtitle">
                  Trouvez rapidement les réponses à vos questions
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      }

      <Container className="faq-content">
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="category-navigation">
              {faqCategories.map((category, index) => (
                <a
                  key={index}
                  href={`#category-${index}`}
                  className="category-item"
                >
                  <span className="category-icon">{category.icon}</span>
                  <span className="category-text">{category.category}</span>
                </a>
              ))}
            </div>

            {filteredFAQ.map((category, categoryIndex) => (
              <div
                key={categoryIndex}
                id={`category-${categoryIndex}`}
                className="category-section"
              >
                <h2 className="category-title">
                  <span className="category-icon">{category.icon}</span>
                  {category.category}
                </h2>
                <Accordion>
                  {category.questions.map((item, index) => (
                    <Accordion.Item
                      key={index}
                      eventKey={`${categoryIndex}-${index}`}
                      className="faq-item"
                    >
                      <Accordion.Header>{item.question}</Accordion.Header>
                      <Accordion.Body>{item.answer}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            ))}
          </Col>
        </Row>
      </Container>

      <div className="contact-section">
        <Container>
          <Row className="justify-content-center text-center">
            <Col md={8}>
              <div className="contact-box">
                <h3>Vous n'avez pas trouvé votre réponse ?</h3>
                <p>
                  Notre équipe est là pour vous aider et répondre à toutes vos
                  questions.
                </p>
                <a href="/contact" className="btn btn-primary contact-btn">
                  Contactez-nous
                  <i className="fas fa-arrow-down ms-2"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default FAQ;
