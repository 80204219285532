import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/Testimonials.css";
import client from "../../../assets/img/avis.png";
import client1 from "../../../assets/img/avis.png";
import client2 from "../../../assets/img/avis.png";

const testimonialsData = [
  {
    name: "Ngono Alice ",
    position: "Project Managers, Future Solutions",
    testimonial:
      "L'équipe a dépassé nos attentes. Leur expertise et leur dévouement ont transformé notre projet en un succès retentissant.",
    image: client,
  },
  {
    name: "Mbarga Martin",
    position: "Project Managers, Future Solutions",
    testimonial:
      "Une expérience incroyable ! Ils ont su comprendre nos besoins et fournir des solutions sur mesure.",
    image: client1,
  },
  {
    name: "Kamga Bernard",
    position: "Project Manager, Creative Agency",
    testimonial:
      "Un service client exceptionnel et des résultats impressionnants. Je recommande vivement cette entreprise.",
    image: client2,
  },
];

const Testimonials = () => {
  return (
    <Container className="my-5">
      <h2 className="text-center mb-4 title">Témoignages de nos clients</h2>
      <Carousel interval={3000} pause="hover">
        {" "}
        {/* Intervalle de 3 secondes et pause au survol */}
        {testimonialsData.map((testimonial, index) => (
          <Carousel.Item key={index}>
            <Row className="justify-content-center">
              <Col md={4} className="text-center">
                <div className="testimonial-container">
                  <img
                    className="rounded-circle testimonial-image mb-3"
                    src={testimonial.image}
                    alt={testimonial.name}
                  />
                  <h5 className="testimonial-name">{testimonial.name}</h5>
                  <p className="testimonial-position">{testimonial.position}</p>
                  <p className="lead testimonial-text">
                    {testimonial.testimonial}
                  </p>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default Testimonials;
