import React from "react";
import ServicePage from "./Components/ServiceComponent";
import seoMarketingImage from "../../assets/img/15917680_5666022.webp";
import backgroundMark from "../../assets/img/seo-3007488_1920.webp";
import {
  FaBullhorn,
  FaChartLine,
  FaEnvelope,
  FaSearch,
  FaCog,
} from "react-icons/fa";

const styles = {
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconText: {
    marginLeft: "0.5rem",
  },
};

const SEOAndMarketing = () => {
  return (
    <ServicePage
      title="SEO et Marketing Digital"
      description={
        <div>
          <p>
            Nos services de SEO et Marketing Digital visent à améliorer votre
            visibilité en ligne et à attirer un public ciblé. Nous utilisons des
            techniques éprouvées pour optimiser votre site web et générer du
            trafic organique.
          </p>
          <p>
            Que vous souhaitiez améliorer votre classement dans les moteurs de
            recherche, lancer une campagne marketing efficace ou développer
            votre présence en ligne, notre équipe est là pour vous aider.
          </p>
          <div style={styles.descriptionContainer}>
            <div style={styles.iconContainer}>
              <FaBullhorn size={24} />
              <span style={styles.iconText}>Publicité en ligne</span>
            </div>
            <div style={styles.iconContainer}>
              <FaChartLine size={24} />
              <span style={styles.iconText}>Analyse de marché</span>
            </div>
            <div style={styles.iconContainer}>
              <FaEnvelope size={24} />
              <span style={styles.iconText}>Email marketing</span>
            </div>
            <div style={styles.iconContainer}>
              <FaSearch size={24} /> {/* Icône pour la recherche SEO */}
              <span style={styles.iconText}>Optimisation SEO</span>
            </div>
            <div style={styles.iconContainer}>
              <FaCog size={24} />
              <span style={styles.iconText}>Optimisation des campagnes</span>
            </div>
          </div>
        </div>
      }
      imageUrl={seoMarketingImage}
      altText="SEO and Marketing Illustration"
      heroBackgroundImage={backgroundMark}
    />
  );
};

export default SEOAndMarketing;
