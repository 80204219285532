import React, { useEffect } from "react";
import "./ScrollProgress.css";

const ScrollProgress = () => {
  useEffect(() => {
    const handleScroll = () => {
      const winScroll = document.documentElement.scrollTop;
      //Calcule la hauteur totale du document en soustrayant la hauteur visible de la fenêtre
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      //Calcule le pourcentage de défilement, Cela donne un pourcentage qui représente combien de la page a été défilée.
      const scrolled = (winScroll / height) * 100;

      const progressBar = document.querySelector(".scroll-progress");
      if (progressBar) {
        progressBar.style.width = scrolled + "%";
      }
    };

    // Ajouter l'observer pour les animations au scroll
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    });

    document.querySelectorAll(".fade-in").forEach((el) => observer.observe(el));

    // Ajouter l'écouteur de scroll
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      observer.disconnect();
    };
  }, []);

  return <div className="scroll-progress"></div>;
};

export default ScrollProgress;
