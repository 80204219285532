import React from "react";
import SolutionDetail from "./Components/SolutionDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faChalkboardTeacher,
  faBook,
  faCalendarDays,
  faSchool,
  faUniversity,
  faUserGraduate,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import EducationImage from "../../assets/img/3837-min.webp";

const EducationSolution = () => {
  const solutionData = {
    title: "Solutions Éducatives",
    category: "Éducation",
    description:
      "Plateforme complète de gestion scolaire et universitaire pour optimiser l'administration et l'apprentissage.",
    imageBg: `url(${EducationImage})`,
    features: [
      {
        icon: <FontAwesomeIcon icon={faGraduationCap} />,
        title: "Gestion Académique",
        description: "Suivi complet du parcours académique",
      },
      {
        icon: <FontAwesomeIcon icon={faChalkboardTeacher} />,
        title: "Gestion des Cours",
        description: "Organisation et planification des enseignements",
      },
      {
        icon: <FontAwesomeIcon icon={faBook} />,
        title: "Bibliothèque",
        description: "Gestion des ressources pédagogiques",
      },
      {
        icon: <FontAwesomeIcon icon={faCalendarDays} />,
        title: "Planning",
        description: "Emplois du temps et calendrier scolaire",
      },
    ],
    benefits: [
      "Automatisation des tâches administratives",
      "Suivi en temps réel des performances",
      "Communication facilitée",
      "Gestion optimisée des ressources",
      "Reporting détaillé",
      "Accessibilité multiplateforme",
    ],
    targetAudience: [
      {
        icon: <FontAwesomeIcon icon={faSchool} />,
        title: "Écoles",
        description: "Pour les établissements primaires et secondaires",
      },
      {
        icon: <FontAwesomeIcon icon={faUniversity} />,
        title: "Universités",
        description: "Solutions pour l'enseignement supérieur",
      },
      {
        icon: <FontAwesomeIcon icon={faUserGraduate} />,
        title: "Centres de Formation",
        description: "Adapté aux centres de formation professionnelle",
      },
    ],
    modules: [
      {
        title: "Administration",
        features: [
          "Inscription des étudiants",
          "Gestion des classes",
          "Suivi des paiements",
          "Gestion du personnel",
          "Reporting académique",
        ],
      },
      {
        title: "Pédagogie",
        features: [
          "Planning des cours",
          "Gestion des notes",
          "Suivi des absences",
          "Ressources pédagogiques",
          "Communications",
        ],
      },
      {
        title: "Vie Scolaire",
        features: [
          "Activités parascolaires",
          "Transport scolaire",
          "Services de restauration",
          "Événements",
          "Communications parents",
        ],
      },
    ],
    customization: {
      description:
        "Solution adaptable à tous les types d'établissements d'enseignement.",
      options: [
        {
          icon: <FontAwesomeIcon icon={faSchool} />,
          text: "Configuration par niveau",
        },
        {
          icon: <FontAwesomeIcon icon={faChartBar} />,
          text: "Rapports personnalisés",
        },
        {
          icon: <FontAwesomeIcon icon={faBook} />,
          text: "Modules adaptables",
        },
      ],
    },
  };

  return <SolutionDetail {...solutionData} />;
};

export default EducationSolution;
