// MedicalSolution.jsx
import React from "react";
import SolutionDetail from "./Components/SolutionDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospital,
  faUserDoctor,
  faCalendarCheck,
  faFileMedical,
  faFlask,
  faUserNurse,
  faClinicMedical,
  faPrescription,
} from "@fortawesome/free-solid-svg-icons";
import MedicalImage from "../../assets/img/medicalSolution-min.webp";

const MedicalSolution = () => {
  const solutionData = {
    title: "Solutions Médicales",
    category: "Santé",
    description:
      "Système complet de gestion hospitalière et médicale pour optimiser les soins aux patients et l'efficacité opérationnelle.",
    imageBg: `url(${MedicalImage})`,
    features: [
      {
        icon: <FontAwesomeIcon icon={faFileMedical} />,
        title: "Dossier Patient",
        description: "Gestion électronique complète des dossiers médicaux",
      },
      {
        icon: <FontAwesomeIcon icon={faCalendarCheck} />,
        title: "Rendez-vous",
        description: "Planification et suivi des consultations",
      },
      {
        icon: <FontAwesomeIcon icon={faFlask} />,
        title: "Laboratoire",
        description: "Gestion des analyses et résultats",
      },
      {
        icon: <FontAwesomeIcon icon={faPrescription} />,
        title: "Pharmacie",
        description: "Gestion des ordonnances et stocks",
      },
    ],
    benefits: [
      "Amélioration de la qualité des soins",
      "Réduction des erreurs médicales",
      "Optimisation du temps médical",
      "Suivi patient personnalisé",
      "Gestion efficace des ressources",
      "Conformité aux normes de santé",
    ],
    targetAudience: [
      {
        icon: <FontAwesomeIcon icon={faHospital} />,
        title: "Hôpitaux",
        description: "Pour les établissements hospitaliers de toute taille",
      },
      {
        icon: <FontAwesomeIcon icon={faClinicMedical} />,
        title: "Cliniques",
        description: "Adapté aux cliniques privées",
      },
      {
        icon: <FontAwesomeIcon icon={faUserDoctor} />,
        title: "Cabinets",
        description: "Solution pour cabinets médicaux",
      },
    ],
    modules: [
      {
        title: "Gestion Patient",
        features: [
          "Dossier médical électronique",
          "Historique des consultations",
          "Suivi des traitements",
          "Gestion des rendez-vous",
          "Communications patient",
        ],
      },
      {
        title: "Gestion Médicale",
        features: [
          "Planning médical",
          "Prescription électronique",
          "Gestion des analyses",
          "Imagerie médicale",
          "Protocoles de soins",
        ],
      },
      {
        title: "Administration",
        features: [
          "Facturation",
          "Gestion des assurances",
          "Statistiques médicales",
          "Gestion des stocks",
          "Rapports d'activité",
        ],
      },
    ],
    customization: {
      description:
        "Solution modulable selon la spécialité médicale et la taille de l'établissement.",
      options: [
        {
          icon: <FontAwesomeIcon icon={faUserDoctor} />,
          text: "Adaptation par spécialité",
        },
        {
          icon: <FontAwesomeIcon icon={faUserNurse} />,
          text: "Workflows personnalisables",
        },
        {
          icon: <FontAwesomeIcon icon={faHospital} />,
          text: "Modules sur mesure",
        },
      ],
    },
  };

  return <SolutionDetail {...solutionData} />;
};

export default MedicalSolution;
