import axios from "axios";
//import { API_BASE_URL } from "./api.config";
import { API_PROD_URL } from "./api.config";

class ContactService {
  static async sendContactForm(formData) {
    try {
      const response = await axios.post(`${API_PROD_URL}/contacts`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        /* console.log(
          "Erreur lors de l'envoi du formulaire: ",
          error.response.data
        ); */
        // Créer une Error personnalisée
        const customError = new Error(
          error.response.data.message || "Une erreur est survenue"
        );
        customError.status = error.response.status;
        throw customError;
      } else {
        //console.log("Erreur lors de l'envoi du formulaire: ", error.message);
        const networkError = new Error(
          "Une erreur est survenue lors de l'envoi du formulaire."
        );
        networkError.status = 500;
        throw networkError;
      }
    }
  }
}

export default ContactService;
