import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faHandshake,
  faLightbulb,
  faUsers,
  faBuilding,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import "./APropos.css";

const Counter = ({ end, duration = 2000 }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTime = null;
    let animationFrame;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      const currentCount = Math.min(
        Math.floor((progress / duration) * end),
        end
      );

      setCount(currentCount);

      if (progress < duration) {
        animationFrame = requestAnimationFrame(animate);
      }
    };

    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration]);

  return <>{count}</>;
};

const Apropos = () => {
  const [startCounting, setStartCounting] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCounting(true);
        }
      },
      { threshold: 0.1 }
    );

    const statsSection = document.querySelector(".apropos-hero-stats");
    if (statsSection) {
      observer.observe(statsSection);
    }

    return () => observer.disconnect();
  }, []);

  const teamMembers = [
    {
      name: "Stelotech Consulting",
      role: "PDG & Fondateur",
      initials: "SP",
      linkedin: "#",
      twitter: "#",
    },
    {
      name: "Stelotech Consulting",
      role: "Directeur Marketing",
      initials: "SP",
      linkedin: "#",
      twitter: "#",
    },
    {
      name: "Stelotech Consulting",
      role: "Directeur Technique",
      initials: "SP",
      linkedin: "#",
      twitter: "#",
    },
  ];

  return (
    <div className="apropos-page">
      {/* Section Hero */}
      <div className="apropos-hero">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="apropos-hero-content" data-aos="fade-right">
                <h1>Votre Partenaire Digital de Confiance</h1>
                <p>
                  Transformons ensemble vos idées en solutions numériques
                  innovantes.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="apropos-hero-stats" data-aos="fade-left">
                <Row>
                  {[
                    { number: 9, text: "Projets réalisés", icon: faBuilding },
                    { number: 12, text: "Clients actifs", icon: faUsers },
                    {
                      number: 2,
                      text: "Années d'expertise",
                      icon: faCertificate,
                    },
                    { number: 98, text: "Satisfaction client %", icon: faStar },
                  ].map((stat, index) => (
                    <Col xs={6} key={index} className="mb-4">
                      <div className="apropos-stat-card">
                        <FontAwesomeIcon
                          icon={stat.icon}
                          className="apropos-stat-icon"
                        />
                        <h3>
                          {startCounting && <Counter end={stat.number} />}
                          {stat.number === 98 && "%"}
                        </h3>
                        <p>{stat.text}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Section Notre Entreprise */}
      <section className="apropos-histoire">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} data-aos="fade-right">
              <div className="apropos-presentation">
                <h2>Qui sommes-nous ?</h2>
                <div className="apropos-presentation-content">
                  <div className="apropos-presentation-section">
                    <h3>Notre Mission</h3>
                    <p>
                      Nous nous engageons à fournir des solutions numériques
                      innovantes qui transforment les entreprises et créent de
                      la valeur durable pour nos clients. Notre expertise
                      technique combinée à notre approche centrée sur le client
                      nous permet de délivrer des résultats exceptionnels.
                    </p>
                  </div>

                  <div className="apropos-presentation-section">
                    <h3>Notre Approche</h3>
                    <p>
                      Nous adoptons une méthodologie agile et collaborative,
                      travaillant en étroite collaboration avec nos clients pour
                      comprendre leurs besoins spécifiques et développer des
                      solutions sur mesure. Notre équipe d'experts passionnés
                      s'engage à maintenir les plus hauts standards de qualité
                      dans chaque projet.
                    </p>
                  </div>

                  <div className="apropos-presentation-section">
                    <h3>Notre Expertise</h3>
                    <p>
                      Spécialisés dans le développement web, mobile et les
                      solutions digitales innovantes, nous maîtrisons les
                      dernières technologies pour créer des expériences
                      numériques performantes et engageantes. Notre expertise
                      couvre l'ensemble du cycle de développement, de la
                      conception à la maintenance.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} data-aos="fade-left">
              <div className="apropos-values">
                <h2>Nos valeurs</h2>
                <div className="apropos-value-item">
                  <FontAwesomeIcon icon={faStar} />
                  <h4>Excellence</h4>
                  <p>
                    Nous visons l'excellence dans chaque aspect de notre
                    travail, en maintenant les plus hauts standards de qualité
                    et de professionnalisme.
                  </p>
                </div>
                <div className="apropos-value-item">
                  <FontAwesomeIcon icon={faHandshake} />
                  <h4>Confiance</h4>
                  <p>
                    Nous construisons des relations durables basées sur la
                    transparence, l'intégrité et le respect mutuel.
                  </p>
                </div>
                <div className="apropos-value-item">
                  <FontAwesomeIcon icon={faLightbulb} />
                  <h4>Innovation</h4>
                  <p>
                    Nous restons à la pointe de la technologie pour offrir des
                    solutions innovantes qui répondent aux défis d'aujourd'hui
                    et de demain.
                  </p>
                </div>
              </div>
            </Col>

            {/* Section Équipe */}
            <section className="apropos-equipe">
              <Container>
                <h2 className="text-center mb-5">Notre équipe</h2>
                <Row>
                  {teamMembers.map((member, index) => (
                    <Col
                      lg={3}
                      md={6}
                      className="mb-4"
                      key={index}
                      data-aos="fade-up"
                    >
                      <div className="apropos-membre-card">
                        <div className="apropos-membre-img">
                          <div className="apropos-membre-initiale">
                            {member.initials}
                          </div>
                        </div>
                        <h4>{member.name}</h4>
                        <p>{member.role}</p>
                        <div className="apropos-membre-social">
                          <a href={member.linkedin}>
                            <FontAwesomeIcon icon={faLinkedin} />
                          </a>
                          <a href={member.twitter}>
                            <FontAwesomeIcon icon={faTwitter} />
                          </a>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </section>
          </Row>
        </Container>
      </section>

      {/*  Section Contact  */}
      <section class="apropos-contact">
        <div class="container">
          <div class="apropos-contact-card">
            <div class="row align-items-center">
              <div class="col-lg-8">
                <h2>Prêt à démarrer votre projet avec nous ?</h2>
                <p>Contactez-nous pour discuter de vos besoins et objectifs.</p>
              </div>
              <div class="col-lg-4 text-lg-end">
                <a href="/contact" class="apropos-contact-btn">
                  Contactez-nous
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Apropos;
