import React from "react";
import { Spinner } from "react-bootstrap";
import "./Spinner.css";

const LoadingSpinner = () => (
  <div className="spinner-wrapper">
    <Spinner animation="border" size="sm" />
    <span className="ms-2">Envoi en cours...</span>
  </div>
);

export default LoadingSpinner;
