import React, { useState } from "react";
import "../Styles/Hero2.css";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import heroImage1 from "../../../assets/img/website-hosting-concept-with-bright-light.webp";
import heroImage2 from "../../../assets/img/business-5475661_1920.webp";
import heroImage3 from "../../../assets/img/smartphone-6701409_1920.webp";

const HeroSection = () => {
  const [index, setIndex] = useState(0);

  const slides = [
    {
      image: heroImage1,
      title: "Transformez vos idées en réalité !",
      description: [
        "Développement d'applications sur mesure",
        "Solutions innovantes pour votre entreprise",
        "Expertise technique de pointe",
      ],
      highlight: "Ensemble, construisons l'avenir de votre entreprise.",
    },
    {
      image: heroImage2,
      title: "Solutions digitales innovantes",
      description: [
        "Applications web & mobile",
        "Design UI/UX moderne",
        "Performance et sécurité garanties",
      ],
      highlight: "Votre succès digital commence ici.",
    },
    {
      image: heroImage3,
      title: "Expertise & Accompagnement",
      description: [
        "Conseil personnalisé",
        "Marketing digital",
        "Support technique continu",
      ],
      highlight: "Une équipe d'experts à votre service.",
    },
  ];

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <section className="hero-section">
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        interval={3000}
        fade
        controls={false}
        pause={false} // Empêche la pause au survol
        className="hero-carousel addBack"
      >
        {slides.map((slide, idx) => (
          <Carousel.Item key={idx} className="carousel-item-custom addBack1">
            <div
              className="carousel-background"
              style={{ backgroundImage: `url(${slide.image})` }}
            ></div>
            <div className="carousel-overlay"></div>
            <div className="container h-100">
              <div className="row h-100 align-items-center">
                <div className="col-lg-6 text-white hero-text-column">
                  <div className="hero-content">
                    <h1 className="hero-title mb-4 animate-in">
                      {slide.title}
                    </h1>
                    <div className="hero-description">
                      {slide.description.map((text, i) => (
                        <p
                          key={i}
                          className={`lead mb-3 fade-in-up delay-${i}`}
                        >
                          {text}
                        </p>
                      ))}
                      <p className="highlight-text fade-in-up delay-3">
                        {slide.highlight}
                      </p>
                    </div>
                    <div className="hero-buttons mt-5 fade-in-up delay-4">
                      <Link
                        to="/apropos"
                        className="btn btn-primary btn-lg me-3 custom-btn"
                        style={{ background: "#0c5b68" }}
                      >
                        A Propos de Nous
                      </Link>
                      <Link
                        to="/contact"
                        className="btn btn-outline-light btn-lg custom-btn"
                        style={{ background: "#e7ab32" }}
                      >
                        Contactez-nous
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
};

export default HeroSection;
