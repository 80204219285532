import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" || event.key === "Escape") {
        navigate("/");
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [navigate]);

  return (
    <div className="not-found">
      <div className="not-found-content">
        <div className="glitch" data-text="404">
          404
        </div>
        <div className="error-message">
          <h2>Oups! Page non trouvée</h2>
          <p>
            La page que vous recherchez semble avoir disparu dans le
            cyberespace...
          </p>
        </div>
        <div className="astronaut">
          <div className="astronaut-helmet"></div>
          <div className="astronaut-body"></div>
          <div className="astronaut-pack"></div>
        </div>
        <button className="back-home" onClick={() => navigate("/")}>
          Retour à l'accueil
        </button>
        <p className="hint">
          Appuyez sur 'Échap' ou 'Entrée' pour revenir à l'accueil
        </p>
      </div>
      <div className="stars"></div>
    </div>
  );
};

export default NotFound;
