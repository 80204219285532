import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faTimes,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import "./ChatbotWidgetv2.css";
import sound from "../../assets/audio/comcell-message-1-id-1111-ls_HOXplSgs.wav";

const ChatbotWidget = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isConversationEnded, setIsConversationEnded] = useState(false);
  const messagesEndRef = useRef(null);
  const audioRef = useRef(new Audio(sound));
  const [isSoundEnabled, setIsSoundEnabled] = useState(true);
  const mainQuestions = [
    {
      id: 1,
      text: "🛠️ Nos Services",
      subQuestions: [
        {
          id: "1.1",
          text: "💻 Solutions Digitales",
          subQuestions: [
            {
              id: "1.1.1",
              text: "🌐 Site Web sur mesure",
              subQuestions: [
                {
                  id: "1.1.1.1",
                  text: "Caractéristiques techniques",
                  answer: `Nos sites web sur mesure incluent :
✓ Architecture moderne (React, Vue.js, Angular)
✓ Performance optimisée
✓ Sécurité renforcée
✓ Base de données optimisée
✓ API RESTful
✓ Tests automatisés
✓ Déploiement continu (CI/CD)`,
                },
                {
                  id: "1.1.1.2",
                  text: "Fonctionnalités avancées",
                  answer: `Fonctionnalités disponibles :
✓ Authentification sécurisée
✓ Gestion de contenu (CMS)
✓ Multilingue
✓ Analytics avancés
✓ Intégration CRM/ERP
✓ Chat en direct
✓ Systèmes de notification`,
                },
              ],
            },
            {
              id: "1.1.2",
              text: "📱 Applications Mobiles",
              subQuestions: [
                {
                  id: "1.1.2.1",
                  text: "Technologies",
                  answer: `Nos technologies mobiles :
✓ React Native
✓ Flutter
✓ iOS natif (Swift)
✓ Android natif (Kotlin)
✓ PWA (Progressive Web Apps)`,
                },
                {
                  id: "1.1.2.2",
                  text: "Fonctionnalités",
                  answer: `Fonctionnalités mobiles :
✓ Mode hors-ligne
✓ Géolocalisation
✓ Notifications push
✓ Paiements in-app
✓ Synchronisation cloud
✓ Analytics mobiles`,
                },
              ],
            },
            {
              id: "1.1.3",
              text: "🛍️ Solutions E-commerce",
              subQuestions: [
                {
                  id: "1.1.3.1",
                  text: "Plateformes",
                  answer: `Nos solutions e-commerce :
✓ WooCommerce personnalisé
✓ Shopify Plus
✓ PrestaShop avancé
✓ Solution sur mesure`,
                },
                {
                  id: "1.1.3.2",
                  text: "Fonctionnalités avancées",
                  answer: `Fonctionnalités e-commerce :
✓ Multi-boutiques
✓ Gestion stocks multi-entrepôts
✓ Marketplace
✓ Programme fidélité
✓ Dropshipping
✓ Paiements internationaux`,
                },
              ],
            },
          ],
        },
        {
          id: "1.2",
          text: "🎯 Marketing Digital",
          subQuestions: [
            {
              id: "1.2.1",
              text: "📊 SEO & Analytics",
              subQuestions: [
                {
                  id: "1.2.1.1",
                  text: "Optimisation SEO",
                  answer: `Services SEO :
✓ Audit technique complet
✓ Optimisation on-page
✓ Stratégie de contenu
✓ Link building
✓ SEO local
✓ Suivi positions
✓ Rapports mensuels`,
                },
                {
                  id: "1.2.1.2",
                  text: "Analytics & Tracking",
                  answer: `Solutions analytics :
✓ Google Analytics 4
✓ Tag Manager
✓ Heat mapping
✓ A/B Testing
✓ Conversion tracking
✓ Personnalisation
✓ Attribution modeling`,
                },
              ],
            },
            {
              id: "1.2.2",
              text: "💡 Marketing Automation",
              answer: `Services automation :
✓ Email marketing
✓ SMS marketing
✓ Marketing automation
✓ CRM integration
✓ Lead scoring
✓ Segmentation
✓ Workflows personnalisés`,
            },
          ],
        },
        {
          id: "1.3",
          text: "🔧 Services Techniques",
          subQuestions: [
            {
              id: "1.3.1",
              text: "☁️ Cloud & DevOps",
              answer: `Services cloud :
✓ AWS/Azure/GCP
✓ Kubernetes
✓ Docker
✓ CI/CD
✓ Monitoring
✓ Scalabilité
✓ Sécurité cloud`,
            },
            {
              id: "1.3.2",
              text: "🔒 Sécurité & Performance",
              answer: `Services sécurité :
✓ Audit sécurité
✓ Tests d'intrusion
✓ WAF
✓ DDoS protection
✓ SSL/TLS
✓ Backup strategy
✓ Disaster recovery`,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      text: "💰 Tarifs et Devis",
      subQuestions: [
        {
          id: "2.1",
          text: "📊 Grille tarifaire",
          subQuestions: [
            {
              id: "2.1.1",
              text: "Sites web",
              answer:
                "Nos tarifs sites web :\n✓ Site vitrine : sur devis\n✓ E-commerce : sur devis\n✓ Application web : sur devis",
            },
            {
              id: "2.1.2",
              text: "Services additionnels",
              answer:
                "Services complémentaires :\n✓ Maintenance : Sur devis\n✓ SEO : sur devis\n✓ Hébergement : sur",
            },
          ],
        },
        {
          id: "2.2",
          text: "📝 Demander un devis",
          action: "contact",
        },
      ],
    },
    {
      id: 3,
      text: "⏱️ Délais et process",
      subQuestions: [
        {
          id: "3.1",
          text: "⌛ Délais moyens",
          answer:
            "Nos délais de réalisation :\n✓ Site vitrine : 4-8 semaines\n✓ E-commerce : 8-12 semaines\n✓ Application web : 12+ semaines",
        },
        {
          id: "3.2",
          text: "📋 Processus de travail",
          answer:
            "Notre méthode :\n1. Analyse des besoins\n2. Proposition & devis\n3. Design & validation\n4. Développement\n5. Tests & livraison",
        },
      ],
    },
    {
      id: 4,
      text: "👥 Contacter un conseiller",
      action: "contact",
    },
  ];

  useEffect(() => {
    audioRef.current.volume = 0.5;
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const playNotificationSound = () => {
    if (isSoundEnabled) {
      audioRef.current
        .play()
        .catch((err) =>
          console.log("Echec de la lecture de notification: ", err)
        );
    }
  };

  const addMessage = (message, isUser = false) => {
    const now = new Date();
    const timeString = now.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const newMessage = {
      id: Date.now(),
      text: message,
      timestamp: timeString,
      isUser,
    };
    setMessages((prev) => [...prev, newMessage]);
    if (!isUser) {
      playNotificationSound();
    }
  };

  const simulateTyping = async (callback, duration = 1500) => {
    setIsTyping(true);
    await new Promise((resolve) => setTimeout(resolve, duration));
    setIsTyping(false);
    callback();
  };

  const handleOpen = () => {
    setIsOpen(true);
    if (isConversationEnded || messages.length === 0) {
      setMessages([]);
      setIsConversationEnded(false);
      simulateTyping(() => {
        addMessage(
          "👋 Bonjour et bienvenue! Je suis votre assistant virtuel, prêt à vous aider dans votre projet. Comment puis-je vous être utile aujourd'hui? 🚀"
        );
        setCurrentQuestions(mainQuestions);
      });
    }
  };

  const handleQuestionClick = (question) => {
    addMessage(question.text, true);
    setCurrentQuestions([]);

    if (question.subQuestions) {
      simulateTyping(() => {
        addMessage("Voici les options disponibles:");
        setCurrentQuestions(question.subQuestions);
      });
    } else {
      simulateTyping(() => {
        addMessage(question.answer);
        askForMoreQuestions();
      });
    }
  };

  const askForMoreQuestions = () => {
    simulateTyping(() => {
      addMessage("Puis-je vous aider avec autre chose ? 😊");
      setCurrentQuestions([
        {
          id: "more-yes",
          text: "✅ Oui, j'ai d'autres questions",
          action: "resetQuestions",
        },
        { id: "more-no", text: "👍 Non, je suis satisfait", action: "endChat" },
        {
          id: "more-contact",
          text: "👤 Je souhaite parler à un conseiller",
          action: "contactForm",
        },
      ]);
    });
  };
  const handleActionClick = (action) => {
    switch (action) {
      case "resetQuestions":
        setMessages([]);
        setIsConversationEnded(false);
        simulateTyping(() => {
          addMessage(
            "🎉 Ravi de pouvoir continuer à vous aider! Que souhaitez-vous savoir?"
          );
          setCurrentQuestions(mainQuestions);
        });
        break;
      case "endChat":
        addMessage("Merci pour cette conversation! 👋", true);
        simulateTyping(() => {
          addMessage(
            "Ce fut un plaisir de vous aider! N'hésitez pas à revenir si vous avez d'autres questions. Belle journée! ✨"
          );
          setIsConversationEnded(true);
          setCurrentQuestions([
            {
              id: "new-chat",
              text: "🔄 Démarrer une nouvelle discussion",
              action: "resetQuestions",
            },
          ]);
        });
        break;
      case "contactForm":
        handleContactClick();
        break;
      default:
        break;
    }
  };

  const handleContactClick = () => {
    addMessage("Je souhaite être contacté par un conseiller", true);
    simulateTyping(() => {
      addMessage("Je vous redirige vers notre formulaire de contact... 📝");
      setTimeout(() => {
        navigate("/contact#contact-form");
      }, 1000);
    });
  };

  return (
    <div className="chatbot-container">
      <button
        className="chatbot-button"
        onClick={() => (isOpen ? setIsOpen(false) : handleOpen())}
        aria-label="Chat with us"
      >
        <FontAwesomeIcon icon={faComment} className="chat-icon" />
      </button>

      {isOpen && (
        <div className="chat-window animate-slide-up">
          <div className="chat-header">
            <h3>
              {isConversationEnded ? "Conversation terminée" : "Chat Assistant"}
            </h3>
            <button
              className="sound-toogle-button"
              onClick={() => setIsSoundEnabled(!isSoundEnabled)}
              aria-label={
                isSoundEnabled ? "Désactiver le son" : "Activer le son"
              }
            >
              <FontAwesomeIcon
                icon={isSoundEnabled ? faVolumeUp : faVolumeMute}
              />
            </button>
            <button className="close-button" onClick={() => setIsOpen(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <div className="chat-body">
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`message ${
                  msg.isUser ? "user-message" : "bot-message"
                }`}
              >
                <span className="message-time">{msg.timestamp}</span>
                <div className="message-content">{msg.text}</div>
              </div>
            ))}

            {isTyping && (
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}

            {!isTyping && currentQuestions.length > 0 && (
              <div className="question-buttons">
                {currentQuestions.map((q) => (
                  <button
                    key={q.id}
                    onClick={() => {
                      if (q.action === "contact") {
                        handleContactClick();
                      } else if (q.action) {
                        handleActionClick(q.action);
                      } else {
                        handleQuestionClick(q);
                      }
                    }}
                    className="question-button animate-fade-in"
                  >
                    {q.text}
                  </button>
                ))}
              </div>
            )}
            {isConversationEnded && (
              <div className="conversation-ended-banner">
                <p>Cette conversation est terminée</p>
                <button
                  onClick={() => handleActionClick("resetQuestions")}
                  className="new-conversation-button"
                >
                  Démarrer une nouvelle conversation
                </button>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotWidget;
