import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/ServicePage.css";
import "../Styles/Service.css";

const ServicePage = ({
  title,
  description,
  imageUrl,
  altText,
  heroBackgroundImage,
}) => {
  const navigate = useNavigate();
  const [activeProcess, setActiveProcess] = useState(0);

  const process = [
    {
      title: "Découverte",
      description: "Discussion approfondie sur vos besoins et objectifs",
      steps: [
        "Analyse des besoins",
        "Étude de faisabilité",
        "Définition des objectifs",
      ],
    },
    {
      title: "Proposition",
      description: "Élaboration d'une solution sur mesure",
      steps: [
        "Devis détaillé",
        "Planning prévisionnel",
        "Spécifications techniques",
      ],
    },
    {
      title: "Création",
      description: "Développement et design de votre projet",
      steps: ["Design UX/UI", "Développement", "Tests qualité"],
    },
    {
      title: "Livraison",
      description: "Mise en production et accompagnement",
      steps: ["Déploiement", "Formation", "Support"],
    },
  ];

  const handleInterested = () => {
    navigate("/contact", { state: { fromServices: true } });
  };

  return (
    <div className="services-page">
      {/* Hero Section */}
      <div
        className="services-hero2"
        style={{ backgroundImage: `url(${heroBackgroundImage})` }}
      >
        <div className="services-hero">
          <h1>{title}</h1>
          <p>Des solutions digitales sur mesure pour votre succès</p>
        </div>
      </div>

      {/* Main Section avec description et imge */}
      <div className="service-page-main">
        <div className="service-page-content">
          <div className="service-page-descriptiona">
            <h2 className="description-title">Description</h2>
            <p>{description}</p>
          </div>

          <div className="service-page-image">
            <img src={imageUrl} alt={altText} />
          </div>
        </div>
      </div>

      {/* Process Section */}
      <section className="process-section">
        <h2>Notre Processus</h2>
        <div className="process-timeline">
          {process.map((step, index) => (
            <div
              className={`process-step ${
                activeProcess === index ? "active" : ""
              }`}
              key={index}
              onMouseEnter={() => setActiveProcess(index)}
            >
              <div className="process-number">{index + 1}</div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
              <ul className="process-details">
                {step.steps.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="ctaa-section">
        <h2>Prêt à démarrer votre projet ?</h2>
        <p>
          Discutons de vos besoins et trouvons la meilleure solution ensemble.
        </p>
        <button className="ctaa-button" onClick={handleInterested}>
          Contactez-nous
        </button>
      </section>
    </div>
  );
};

export default ServicePage;
