import React from "react";
import { Helmet } from "react-helmet-async";

// Configuration globale du site
const siteConfig = {
  siteName: "Stelotech Consulting",
  baseUrl: "https://stelotechconsulting.com",
  phone: "+237 678463251",
  email: "contact@stelotechconsulting.com",
  socialMedia: {
    facebook: "https://www.facebook.com/stelotechconsulting",
    twitter: "https://twitter.com/stelotech",
    linkedin: "https://www.linkedin.com/company/stelotech-consulting",
    instagram: "https://www.instagram.com/stelotechconsulting",
  },
};

// Schémas globaux
const globalSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: siteConfig.siteName,
  url: siteConfig.baseUrl,
  /* potentialAction: {
    "@type": "SearchAction",
    target: `${siteConfig.baseUrl}/search?q={search_term_string}`,
    "query-input": "required name=search_term_string",
  }, */
};

const organizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: siteConfig.siteName,
  url: siteConfig.baseUrl,
  logo: `${siteConfig.baseUrl}/logo.png`,
  sameAs: Object.values(siteConfig.socialMedia),
  contactPoint: {
    "@type": "ContactPoint",
    telephone: siteConfig.phone,
    email: siteConfig.email,
    contactType: "customer service",
    areaServed: "CM",
    availableLanguage: ["French", "English"],
  },
};

// Détails des pages
const pageDetails = {
  "/": {
    title: "Stelotech Consulting | Solutions Digitales Innovantes au Cameroun",
    description:
      "Stelotech Consulting, votre partenaire digital au Cameroun. Expertise en développement web, applications mobiles, marketing digital et solutions technologiques sur mesure.",
    keywords:
      "Stelotech, consulting, digital, Cameroun, développement web, applications mobiles, marketing digital",
    schema: {
      "@type": "WebPage",
      name: "Accueil - Stelotech Consulting",
      description:
        "Page d'accueil de Stelotech Consulting, expert en solutions digitales au Cameroun",
    },
  },
  "/acceuil": {
    title: "Stelotech Consulting | Solutions Digitales Innovantes au Cameroun",
    description:
      "Stelotech Consulting, votre partenaire digital au Cameroun. Expertise en développement web, applications mobiles, marketing digital et solutions technologiques sur mesure.",
    keywords:
      "Stelotech, consulting, digital, Cameroun, développement web, applications mobiles, marketing digital",
    schema: {
      "@type": "WebPage",
      name: "Accueil - Stelotech Consulting",
      description:
        "Page d'accueil de Stelotech Consulting, expert en solutions digitales au Cameroun",
    },
  },
  /*  "/services": {
    title: "Nos Services | Stelotech Consulting",
    description:
      "Découvrez nos services professionnels: développement web, applications mobiles, marketing digital, design UI/UX et solutions technologiques personnalisées.",
    keywords:
      "services, développement web, applications mobiles, marketing digital, UI/UX design, solutions technologiques",
    schema: {
      "@type": "Service",
      name: "Services Stelotech",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Services professionnels de Stelotech Consulting",
    }, 
  },*/

  "/services/web-development": {
    title: "Développement Web | Stelotech Consulting",
    description:
      "Services de développement web professionnels. Sites vitrine, e-commerce, applications web sur mesure et solutions web innovantes.",
    keywords:
      "développement web, sites web, e-commerce, applications web, frontend, backend",
    schema: {
      "@type": "Service",
      name: "Développement Web",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Services de développement web professionnel",
    },
  },
  "/services/Web-development": {
    title: "Développement Web | Stelotech Consulting",
    description:
      "Services de développement web professionnels. Sites vitrine, e-commerce, applications web sur mesure et solutions web innovantes.",
    keywords:
      "développement web, sites web, e-commerce, applications web, frontend, backend",
    schema: {
      "@type": "Service",
      name: "Développement Web",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Services de développement web professionnel",
    },
  },
  "/services/mobile-apps": {
    title: "Développement Mobile | Stelotech Consulting",
    description:
      "Création d'applications mobiles iOS et Android. Solutions mobiles innovantes et performantes pour votre entreprise.",
    keywords: "applications mobiles, iOS, Android, développement mobile, apps",
    schema: {
      "@type": "Service",
      name: "Développement Mobile",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Services de développement d'applications mobiles",
    },
  },
  "/services/seo-marketing": {
    title: "SEO & Marketing Digital | Stelotech Consulting",
    description:
      "Optimisation SEO, marketing digital et stratégies de visibilité en ligne pour votre entreprise.",
    keywords:
      "SEO, marketing digital, référencement, visibilité web, stratégie digitale",
    schema: {
      "@type": "Service",
      name: "SEO et Marketing Digital",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Services de SEO et marketing digital",
    },
  },
  "/services/ui-ux-design": {
    title: "Design UI/UX | Stelotech Consulting",
    description:
      "Design d'interfaces utilisateur et expérience utilisateur. Création d'interfaces modernes et intuitives.",
    keywords:
      "UI design, UX design, interface utilisateur, expérience utilisateur, design web",
    schema: {
      "@type": "Service",
      name: "Design UI/UX",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Services de design UI/UX professionnel",
    },
  },
  "/apropos": {
    title: "À Propos | Stelotech Consulting",
    description:
      "Découvrez Stelotech Consulting, notre histoire, nos valeurs et notre vision du numérique au Cameroun.",
    keywords: "à propos, Stelotech, histoire, valeurs, vision, équipe",
    schema: {
      "@type": "AboutPage",
      name: "À Propos de Stelotech Consulting",
      description: "Présentation de Stelotech Consulting",
    },
  },
  "/contact": {
    title: "Contact | Stelotech Consulting",
    description:
      "Contactez Stelotech Consulting pour vos projets digitaux. Notre équipe est à votre écoute.",
    keywords: "contact, devis, projet digital, consultation, Stelotech",
    schema: {
      "@type": "ContactPage",
      name: "Contact Stelotech Consulting",
      description: "Page de contact de Stelotech Consulting",
    },
  },
  "/solutions/finance": {
    title: "Solutions Financières | Stelotech Consulting",
    description:
      "Solutions digitales pour le secteur financier: fintech, paiement en ligne, gestion financière.",
    keywords:
      "solutions financières, fintech, paiement en ligne, gestion financière",
    schema: {
      "@type": "Service",
      name: "Solutions Financières",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Solutions digitales pour le secteur financier",
    },
  },
  "/solutions/medical": {
    title: "Solutions Médicales | Stelotech Consulting",
    description:
      "Solutions digitales pour le secteur médical: télémédecine, gestion hospitalière, dossiers médicaux.",
    keywords:
      "solutions médicales, télémédecine, santé digitale, gestion hospitalière",
    schema: {
      "@type": "Service",
      name: "Solutions Médicales",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Solutions digitales pour le secteur médical",
    },
  },
  "/solutions/education": {
    title: "Solutions Éducatives | Stelotech Consulting",
    description:
      "Solutions digitales pour l'éducation: e-learning, plateformes éducatives, formation en ligne.",
    keywords:
      "solutions éducatives, e-learning, formation en ligne, éducation digitale",
    schema: {
      "@type": "Service",
      name: "Solutions Éducatives",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Solutions digitales pour l'éducation",
    },
  },
  "/solutions/business": {
    title: "Solutions Business | Stelotech Consulting",
    description:
      "Solutions digitales pour entreprises: gestion, automatisation, transformation digitale.",
    keywords:
      "solutions business, entreprise, gestion, automatisation, transformation digitale",
    schema: {
      "@type": "Service",
      name: "Solutions Business",
      provider: {
        "@type": "Organization",
        name: "Stelotech Consulting",
      },
      description: "Solutions digitales pour entreprises",
    },
  },
};

const SEOHelmet = ({ page }) => {
  const details = pageDetails[page] || pageDetails["/"];
  const currentUrl = `${siteConfig.baseUrl}${page}`;

  return (
    <>
      <Helmet>
        {/* Balises meta de base */}
        <html lang="fr" />
        <title>{details.title}</title>
        <meta name="description" content={details.description} />
        <meta name="keywords" content={details.keywords} />
        <meta name="author" content={siteConfig.siteName} />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="UTF-8" />

        {/* Balise canonique */}
        <link rel="canonical" href={currentUrl} />

        {/* Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:title" content={details.title} />
        <meta property="og:description" content={details.description} />
        <meta property="og:image" content={`${siteConfig.baseUrl}/logo.png`} />
        <meta property="og:site_name" content={siteConfig.siteName} />
        <meta property="og:locale" content="fr_FR" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@stelotech" />
        <meta name="twitter:title" content={details.title} />
        <meta name="twitter:description" content={details.description} />
        <meta name="twitter:image" content={`${siteConfig.baseUrl}/logo.png`} />

        {/* Métadonnées locales */}
        <meta name="geo.region" content="CM" />
        <meta name="geo.placename" content="Cameroun" />
      </Helmet>

      {/* Schémas JSON-LD */}
      <script type="application/ld+json">{JSON.stringify(globalSchema)}</script>
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          ...details.schema,
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": currentUrl,
          },
        })}
      </script>
    </>
  );
};

export default SEOHelmet;
