import React from "react";
import SolutionDetail from "./Components/SolutionDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxes,
  faCalculator,
  faShoppingCart,
  faChartPie,
  faStore,
  faWarehouse,
  faIndustry,
  faTags,
} from "@fortawesome/free-solid-svg-icons";

import BusinessImage from "../../assets/img/18063.webp";

const BusinessSolution = () => {
  const solutionData = {
    title: "Solutions de Gestion",
    category: "Business",
    description:
      "Solutions complètes de gestion commerciale et administrative pour optimiser vos opérations.",
    imageBg: `url(${BusinessImage})`,
    features: [
      {
        icon: <FontAwesomeIcon icon={faBoxes} />,
        title: "Gestion des Stocks",
        description: "Suivi en temps réel des inventaires",
      },
      {
        icon: <FontAwesomeIcon icon={faCalculator} />,
        title: "Comptabilité",
        description: "Gestion comptable et financière",
      },
      {
        icon: <FontAwesomeIcon icon={faShoppingCart} />,
        title: "Ventes",
        description: "Gestion commerciale complète",
      },
      {
        icon: <FontAwesomeIcon icon={faChartPie} />,
        title: "Analyses",
        description: "Tableaux de bord et statistiques",
      },
    ],
    benefits: [
      "Optimisation des processus",
      "Réduction des coûts",
      "Meilleure gestion des stocks",
      "Suivi financier précis",
      "Prise de décision facilitée",
      "Satisfaction client améliorée",
    ],
    targetAudience: [
      {
        icon: <FontAwesomeIcon icon={faStore} />,
        title: "Commerces",
        description: "Pour les commerces de détail",
      },
      {
        icon: <FontAwesomeIcon icon={faWarehouse} />,
        title: "Grossistes",
        description: "Adapté aux grossistes et distributeurs",
      },
      {
        icon: <FontAwesomeIcon icon={faIndustry} />,
        title: "Industries",
        description: "Pour les entreprises industrielles",
      },
    ],
    modules: [
      {
        title: "Gestion Commerciale",
        features: [
          "Facturation",
          "Gestion des commandes",
          "Suivi clients",
          "Gestion des devis",
          "Catalogue produits",
        ],
      },
      {
        title: "Gestion des Stocks",
        features: [
          "Inventaire en temps réel",
          "Approvisionnements",
          "Multi-dépôts",
          "Traçabilité",
          "Alertes stock",
        ],
      },
      {
        title: "Comptabilité",
        features: [
          "Comptabilité générale",
          "Gestion des paiements",
          "Rapports financiers",
          "Budget prévisionnel",
          "Tableau de bord",
        ],
      },
    ],
    customization: {
      description:
        "Solution adaptable à tous les secteurs d'activité et tailles d'entreprise.",
      options: [
        {
          icon: <FontAwesomeIcon icon={faTags} />,
          text: "Paramétrage métier",
        },
        {
          icon: <FontAwesomeIcon icon={faChartPie} />,
          text: "Rapports personnalisés",
        },
        {
          icon: <FontAwesomeIcon icon={faStore} />,
          text: "Modules spécifiques",
        },
      ],
    },
  };

  return <SolutionDetail {...solutionData} />;
};

export default BusinessSolution;
