import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ScroolToUp from "../components/ScrollToTopButton/ScrollToTopButton";
import "./MainLayout.css";
import SEOHelmet from "../components/SEO/SEOHelmet";
import { useLocation } from "react-router-dom";

import ChatbotWidget from "../components/ChatBotWidget/ChatbotWidgetv2";
//import ChatbotWidget from "../components/ChatBotWidget/ChatbotWidget";

const MainLayout = ({ children }) => {
  const location = useLocation();
  /*  console.log("full location", location);
  console.log("path name:", location.pathname); */

  return (
    <div>
      <SEOHelmet page={location.pathname} />
      <Header />
      <ChatbotWidget />
      <main>{children}</main> {/* Tout le contenu des pages sera rendu ici */}
      <ScroolToUp />
      <Footer />
    </div>
  );
};

export default MainLayout;
