import React from "react";
import ServicePage from "./Components/ServiceComponent";
import webDevImage from "../../assets/img/21620.webp";
import backgoundWeb from "../../assets/img/138060.webp";
import {
  FaLaptopCode,
  FaMobileAlt,
  FaShoppingCart,
  FaClipboardList,
  FaChartLine,
} from "react-icons/fa";
const styles = {
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconText: {
    marginLeft: "0.5rem",
  },
};

const WebDevelopment = () => {
  return (
    <ServicePage
      title="Développement Web"
      description={
        <div>
          <p>
            Notre service de développement web offre des solutions sur mesure
            pour votre présence en ligne. Nous créons des sites web modernes,
            réactifs et optimisés pour les performances.
          </p>
          <p>
            Que vous ayez besoin d'un site vitrine, d'une boutique en ligne ou
            d'une application web complexe, notre équipe d'experts vous
            accompagne dans la réalisation de votre projet digital.
          </p>
          <div style={styles.descriptionContainer}>
            <div style={styles.iconContainer}>
              <FaLaptopCode size={24} />
              <span style={styles.iconText}>Sites web responsifs</span>
            </div>
            <div style={styles.iconContainer}>
              <FaMobileAlt size={24} />
              <span style={styles.iconText}>
                Applications web progressives (PWA)
              </span>
            </div>
            <div style={styles.iconContainer}>
              <FaShoppingCart size={24} />
              <span style={styles.iconText}>E-commerce</span>
            </div>
            <div style={styles.iconContainer}>
              <FaClipboardList size={24} />
              <span style={styles.iconText}>Intégration de CMS</span>
            </div>
            <div style={styles.iconContainer}>
              <FaChartLine size={24} />
              <span style={styles.iconText}>Optimisation des performances</span>
            </div>
          </div>
        </div>
      }
      imageUrl={webDevImage}
      altText="Développement Web Illustration"
      heroBackgroundImage={backgoundWeb}
    />
  );
};

export default WebDevelopment;
