// Services.jsx
import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Importation de Link
import "../Styles/Services.css";

const Services = () => {
  useEffect(() => {
    // ajouter un effet lorsqu'on arrive sur un service
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll(".service-card").forEach((card) => {
      observer.observe(card);
    });

    return () => observer.disconnect();
  }, []);

  const services = [
    {
      title: "Développement Web",
      description: "Sites vitrines, e-commerce et applications web",
      icon: "💻",
      features: ["Sites responsive", "SEO optimisé", "Performance maximale"],
      link: "/services/Web-development",
    },
    {
      title: "Applications Mobile",
      description: "Applications native pour iOS et Android",
      icon: "📱",
      features: ["Design intuitif", "Performance native", "Multi-plateforme"],
      link: "/services/mobile-apps",
    },
    {
      title: "Design UI/UX",
      description: "Interfaces modernes",
      icon: "🎨",
      features: ["Maquettes interactives", "Design système"],
      link: "/services/ui-ux-design",
    },
    {
      title: "Marketing Digital",
      description:
        "Stratégies digitales pour développer votre présence en ligne",
      icon: "📈",
      features: ["SEO", "Réseaux sociaux", "Analyse de données"],
      link: "/services/seo-marketing",
    },
  ];

  return (
    <div className="services-container" id="services">
      <div className="services-header">
        <h2 className="section-title">Nos Services</h2>
        <div className="title-underline"></div>
        <p className="section-subtitle">
          Des solutions digitales adaptées à vos besoins
        </p>
      </div>

      <div className="services-gridH">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-icon">{service.icon}</div>
            <div className="service-content">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <ul className="service-features">
                {service.features.map((feature, idx) => (
                  <li key={idx}>
                    <span className="feature-dot"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="service-hover">
              <Link to={service.link} className="learn-more">
                En savoir plus
              </Link>{" "}
            </div>
          </div>
        ))}
      </div>

      <div className="services-cta">
        <button className="cta-button">Découvrir tous nos services</button>
      </div>
    </div>
  );
};

export default Services;
