import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  // faTwitter,
  faLinkedinIn,
  faInstagram,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/img/logo_stelotech-removebg-preview.png";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShrunk, setIsShrunk] = useState(false);
  const [activeLink, setActiveLink] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const services = [
    { title: "Développement Web", path: "/services/web-development" },
    { title: "Design UI/UX", path: "/services/ui-ux-design" },
    { title: "Applications Mobile", path: "/services/mobile-apps" },
    { title: "SEO & Marketing", path: "/services/seo-marketing" },
  ];

  const solutions = [
    { title: "Solution Financière", path: "/solutions/finance" },
    { title: "Solution Médicale", path: "/solutions/medical" },
    { title: "Solution Éducative", path: "/solutions/education" },
    { title: "Solution de Gestion", path: "/solutions/business" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
        if (window.scrollY > 50) {
          setIsShrunk(true);
        } else {
          setIsShrunk(false);
        }
      } else {
        setIsScrolled(false);
        setIsShrunk(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const onUpdateActiveLink = (link) => {
    setActiveLink(link);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="top-header">
        <Container className="d-flex justify-content-between align-items-center">
          <div className="contact-infoH">
            <a href="mailto:contact@stelotechconsulting.com">
              <FontAwesomeIcon icon={faEnvelope} className="me-2" />
              contact@stelotechconsulting.com
            </a>
            <a href="tel:+237678463251">
              <FontAwesomeIcon icon={faPhone} className="me-2" />
              +237 678 463 251
            </a>
            <a href="tel:+237689195316">
              <FontAwesomeIcon icon={faPhone} className="me-2" />
              +237 689 195 316
            </a>
          </div>
          <div className="social-links">
            <span className="testH"> Suivez-Nous:</span>
            <a
              href="https://www.facebook.com/profile.php?id=100090427166643"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            {/* <a
              href="https://www.X.com/stelotech_consulting"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link twitter"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a> */}
            <a
              href="https://www.linkedin.com/stelotech_consulting"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link linkedin"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a
              href="https://www.instagram.com/stelotech_consulting"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://wa.me/237678463251"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link whatsapp"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a
              href="https://www.X.com/stelotech_consulting"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link twiteerX custumBout"
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          </div>
        </Container>
      </div>
      <Navbar
        className={`navbar-expand-lg fixed-top ${
          isScrolled ? "bg-dark" : "bg-transparent"
        } ${isShrunk ? "shrink" : ""}`}
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand className="logo">
              <img
                src={logo}
                alt="Logo Stelotech"
                height={isShrunk ? "50" : "80"}
                className="d-inline-block align-top logo"
              />
            </Navbar.Brand>
          </LinkContainer>

          <div
            className={`menu-toggle ${isMenuOpen ? "active" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div
            className={`nav-overlay ${isMenuOpen ? "show" : ""}`}
            onClick={toggleMenu}
          ></div>

          <Nav className={`ms-auto main-nav ${isMenuOpen ? "show" : ""}`}>
            <LinkContainer to="/">
              <Nav.Link
                className={activeLink === "home" ? "active" : ""}
                onClick={() => onUpdateActiveLink("home")}
              >
                Accueil
                <span className="hover-effect"></span>
              </Nav.Link>
            </LinkContainer>

            <NavDropdown
              title={
                <span className="nav-dropdown-title">
                  Nos Services
                  <span className="hover-effect"></span>
                </span>
              }
              id="services-dropdown"
              className={activeLink === "services" ? "active" : ""}
            >
              {services.map((service, index) => (
                <LinkContainer key={index} to={service.path}>
                  <NavDropdown.Item
                    onClick={() => onUpdateActiveLink("services")}
                  >
                    {service.title}
                  </NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>

            <NavDropdown
              title={
                <span className="nav-dropdown-title">
                  Nos Solutions
                  <span className="hover-effect"></span>
                </span>
              }
              id="solutions-dropdown"
              className={activeLink === "solutions" ? "active" : ""}
            >
              {solutions.map((solution, index) => (
                <LinkContainer key={index} to={solution.path}>
                  <NavDropdown.Item
                    onClick={() => onUpdateActiveLink("solutions")}
                  >
                    {solution.title}
                  </NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>

            <LinkContainer to="/apropos">
              <Nav.Link
                className={activeLink === "apropos" ? "active" : ""}
                onClick={() => onUpdateActiveLink("apropos")}
              >
                A Propos
                <span className="hover-effect"></span>
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/contact">
              <Nav.Link
                className="contact-button"
                onClick={() => onUpdateActiveLink("contact")}
              >
                Contact
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
