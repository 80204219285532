import React from "react";
import ServicePage from "./Components/ServiceComponent";
import uiuxImage from "../../assets/img/16829080_5741168.webp";
import backgroundDesign from "../../assets/img/2150038916.webp";
import {
  FaPaintBrush,
  FaDesktop,
  FaMobileAlt,
  FaUsers,
  FaCog,
} from "react-icons/fa";

const styles = {
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconText: {
    marginLeft: "0.5rem",
  },
};

const UIDesign = () => {
  return (
    <ServicePage
      title="Design UI/UX"
      description={
        <div>
          <p>
            Nos services de design UI/UX se concentrent sur la création
            d'expériences utilisateur intuitives et engageantes. Nous croyons
            que le design doit être à la fois esthétique et fonctionnel, afin
            d'offrir une navigation fluide et agréable.
          </p>
          <p>
            Que vous ayez besoin d'une refonte de votre interface actuelle ou de
            la création d'une nouvelle application, notre équipe de designers
            est prête à transformer vos idées en réalité.
          </p>
          <div style={styles.descriptionContainer}>
            <div style={styles.iconContainer}>
              <FaPaintBrush size={24} />
              <span style={styles.iconText}>Design créatif</span>
            </div>
            <div style={styles.iconContainer}>
              <FaDesktop size={24} />
              <span style={styles.iconText}>Design pour desktop</span>
            </div>
            <div style={styles.iconContainer}>
              <FaMobileAlt size={24} />
              <span style={styles.iconText}>Design mobile</span>
            </div>
            <div style={styles.iconContainer}>
              <FaUsers size={24} />
              <span style={styles.iconText}>Tests utilisateurs</span>
            </div>
            <div style={styles.iconContainer}>
              <FaCog size={24} />
              <span style={styles.iconText}>Prototypage et itération</span>
            </div>
          </div>
        </div>
      }
      imageUrl={uiuxImage}
      altText="UI/UX Design Illustration"
      heroBackgroundImage={backgroundDesign}
    />
  );
};

export default UIDesign;
