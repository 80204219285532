import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaCode,
  FaPalette,
  FaMobile,
  FaRocket,
  FaCog,
  FaChartLine,
} from "react-icons/fa";
import "./Styles/Service.css";

const ServicesPage = () => {
  const navigate = useNavigate();
  const [activeProcess, setActiveProcess] = useState(0);

  const services = [
    {
      icon: <FaCode />,
      title: "Développement Web",
      description:
        "Sites web sur mesure, applications web dynamiques et solutions e-commerce innovantes.",
      details: ["Sites vitrines", "E-commerce", "Applications web", "API REST"],
    },
    {
      icon: <FaPalette />,
      title: "Design UX/UI",
      description:
        "Création d'interfaces utilisateur intuitives et esthétiques pour une expérience optimale.",
      details: [
        "Maquettes",
        "Prototypes",
        "Design System",
        "Tests utilisateurs",
      ],
    },
    {
      icon: <FaMobile />,
      title: "Applications Mobiles",
      description:
        "Développement d'applications mobiles natives et cross-platform performantes.",
      details: ["iOS", "Android", "React Native", "Flutter"],
    },
    {
      icon: <FaRocket />,
      title: "SEO & Performance",
      description:
        "Optimisation pour les moteurs de recherche et amélioration des performances.",
      details: ["Audit SEO", "Optimisation", "Analytics", "Référencement"],
    },
    {
      icon: <FaCog />,
      title: "Maintenance",
      description:
        "Services de maintenance et support technique pour garantir la pérennité de vos projets.",
      details: ["Support 24/7", "Mises à jour", "Sécurité", "Sauvegardes"],
    },
    {
      icon: <FaChartLine />,
      title: "Conseil Digital",
      description:
        "Accompagnement stratégique pour votre transformation digitale.",
      details: ["Stratégie", "Formation", "Audit", "Veille"],
    },
  ];

  const process = [
    {
      title: "Découverte",
      description: "Discussion approfondie sur vos besoins et objectifs",
      steps: [
        "Analyse des besoins",
        "Étude de faisabilité",
        "Définition des objectifs",
      ],
    },
    {
      title: "Proposition",
      description: "Élaboration d'une solution sur mesure",
      steps: [
        "Devis détaillé",
        "Planning prévisionnel",
        "Spécifications techniques",
      ],
    },
    {
      title: "Création",
      description: "Développement et design de votre projet",
      steps: ["Design UX/UI", "Développement", "Tests qualité"],
    },
    {
      title: "Livraison",
      description: "Mise en production et accompagnement",
      steps: ["Déploiement", "Formation", "Support"],
    },
  ];

  const handleInterested = () => {
    navigate("/contact", { state: { fromServices: true } });
  };

  return (
    <div className="services-page">
      {/* Hero Section */}
      <div className="services-hero2">
        <div className="services-hero">
          <h1>Nos Services</h1>
          <p>Des solutions digitales sur mesure pour votre succès</p>
        </div>
      </div>

      {/* Services Grid */}
      <section className="services-grid">
        {services.map((service, index) => (
          <div className="services-card" key={index}>
            <div className="services-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <ul className="services-details">
              {service.details.map((detail, idx) => (
                <li key={idx}>{detail}</li>
              ))}
            </ul>
            <button className="interested-btn" onClick={handleInterested}>
              Je suis intéressé
            </button>
          </div>
        ))}
      </section>

      {/* Process Section */}
      <section className="process-section">
        <h2>Notre Processus</h2>
        <div className="process-timeline">
          {process.map((step, index) => (
            <div
              className={`process-step ${
                activeProcess === index ? "active" : ""
              }`}
              key={index}
              onMouseEnter={() => setActiveProcess(index)}
            >
              <div className="process-number">{index + 1}</div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
              <ul className="process-details">
                {step.steps.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="ctaa-section">
        <h2>Prêt à démarrer votre projet ?</h2>
        <p>
          Discutons de vos besoins et trouvons la meilleure solution ensemble.
        </p>
        <button className="ctaa-button" onClick={handleInterested}>
          Contactez-nous
        </button>
      </section>
    </div>
  );
};

export default ServicesPage;
