import React from "react";
import ServicePage from "./Components/ServiceComponent";
import mobileDevImage from "../../assets/img/2150104471.webp"; // Assurez-vous d'avoir une image appropriée
import backgroundMobil from "../../assets/img/104212.webp";
import {
  FaMobileAlt,
  FaReact,
  FaCode,
  FaRocket,
  FaPaintBrush,
} from "react-icons/fa";

const MobileDevelopment = () => {
  return (
    <ServicePage
      title="Développement Mobile"
      description={
        <div>
          <p>
            Notre service de développement mobile propose des applications
            performantes et intuitives, adaptées aux besoins de votre
            entreprise. Nous développons des applications natives et hybrides
            pour iOS et Android, garantissant une expérience utilisateur
            optimale.
          </p>
          <p>
            Que vous souhaitiez créer une application de gestion, un jeu ou une
            plateforme de services, notre équipe d'experts est là pour réaliser
            votre vision.
          </p>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaMobileAlt size={24} />
              <span style={{ marginLeft: "0.5rem" }}>
                Applications iOS et Android
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaReact size={24} />
              <span style={{ marginLeft: "0.5rem" }}>
                Applications hybrides (React Native, Flutter)
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaCode size={24} />
              <span style={{ marginLeft: "0.5rem" }}>Intégration d'API</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaRocket size={24} />
              <span style={{ marginLeft: "0.5rem" }}>
                Optimisation des performances
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaPaintBrush size={24} />
              <span style={{ marginLeft: "0.5rem" }}>
                Design UI/UX personnalisé
              </span>
            </div>
          </div>
        </div>
      }
      imageUrl={mobileDevImage}
      altText="Développement Mobile Illustration"
      heroBackgroundImage={backgroundMobil}
    />
  );
};

export default MobileDevelopment;
