import React from "react";
/* import Header from "./layouts/Header/Header";
import HeroSection from "./pages/Homepage/components/HeroSection";
import Services from "./pages/Homepage/components/Services";
import Projets from "./pages/Homepage/components/Projets";
import Contact from "./pages/Homepage/components/Contact";
import Footer from "./layouts/Footer/Footer";
import Testimonials from "./pages/Homepage/components/Testimonials";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton";
 */
import "./App.css";
import Scroll from "./components/ScrollProgress/ScroolProgress";

import { RouterProvider } from "react-router";
import Router from "./routes/router";

const App = () => {
  return (
    <>
      <Scroll />
      <RouterProvider router={Router} />
    </>
  );
};

export default App;
/*{
     <div className="app">
      <Header />
      <main>
        <HeroSection />
        <section className="services-section" id="services">
          <Services />
        </section>
        <section className="projects-section" id="projets">
          <Projets />
        </section>
        <section className="projects-section" id="testimonial">
          <Testimonials />
        </section>
        <section className="contact-section" id="contact">
          <Contact />
        </section>
        <section className="contact-section" id="contact">
          <ScrollToTopButton />
        </section>
      </main>
      <Footer />
    </div> 
   
  };*/
