import React from "react";
import { createBrowserRouter } from "react-router-dom";
/* import HeroSection from "../pages/Homepage/components/HeroSection";
import Services from "../pages/Homepage/components/Services";
import Projets from "../pages/Homepage/components/Projets";
import Contact from "../pages/Homepage/components/Contact";
import Testimonials from "../pages/Homepage/components/Testimonials";
 */

import MainLayout from "../layouts/MainLayouts";
import Homepage from "../pages/Homepage/HomePage";
import NotFound from "../pages/NotFound/NotFound";
import ServicePage from "../pages/Services/Services";
import WebService from "../pages/Services/WebDev";
import MobilService from "../pages/Services/MobilDev";
import MarketingService from "../pages/Services/Marketing";
import DesignService from "../pages/Services/Design";
import ContactPage from "../pages/contact/ContactPage";
import Apropos from "../pages/APROPOS/APropos";
import FinanceSolution from "../pages/NosSolutions/FinanceSolution";
import MedicalSolution from "../pages/NosSolutions/MedicalSolution";
import EducationSolution from "../pages/NosSolutions/EducationSolution";
import BusinessSolution from "../pages/NosSolutions/BusinessSoluytion";

//import FAQ from "../pages/FAQ/FAQ";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MainLayout>
        <Homepage />
      </MainLayout>
    ),
  },
  {
    path: "/acceuil",
    element: (
      <MainLayout>
        <Homepage />
      </MainLayout>
    ),
  },

  {
    path: "/contact",
    element: (
      <MainLayout>
        <ContactPage />
      </MainLayout>
    ),
  },

  {
    path: "/services",
    element: (
      <MainLayout>
        <ServicePage />
      </MainLayout>
    ),
  },
  {
    path: "/services/Web-development",
    element: (
      <MainLayout>
        <WebService />
      </MainLayout>
    ),
  },

  {
    path: "/services/mobile-apps",
    element: (
      <MainLayout>
        <MobilService />
      </MainLayout>
    ),
  },
  {
    path: "/services/seo-marketing",
    element: (
      <MainLayout>
        <MarketingService />
      </MainLayout>
    ),
  },
  {
    path: "/services/ui-ux-design",
    element: (
      <MainLayout>
        <DesignService />
      </MainLayout>
    ),
  },
  {
    path: "/apropos",
    element: (
      <MainLayout>
        <Apropos />
      </MainLayout>
    ),
  },

  {
    path: "/solutions/finance",
    element: (
      <MainLayout>
        <FinanceSolution />
      </MainLayout>
    ),
  },
  {
    path: "/solutions/medical",
    element: (
      <MainLayout>
        <MedicalSolution />
      </MainLayout>
    ),
  },
  {
    path: "/solutions/education",
    element: (
      <MainLayout>
        <EducationSolution />
      </MainLayout>
    ),
  },
  {
    path: "/solutions/business",
    element: (
      <MainLayout>
        <BusinessSolution />
      </MainLayout>
    ),
  },

  /*  {
    path: "/faq",
    element: (
      <MainLayout>
        <FAQ />
      </MainLayout>
    ),
  }, */
  {
    path: "*", // page non présente
    element: <NotFound />,
  },
]);

export default router;
