import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Styles/Contact.css";
import ContactService from "../../../services/contact.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoadingSpinner from "../../../components/Spinner/Spinner";

const Contact = () => {
  const [formData, setFormData] = useState({
    nom_utilisateur: "",
    email: "",
    telephone: "",
    subject: "",
    message: "",
    countryCode: "cm",
    fullPhone: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const phoneNumberLengths = {
    cm: { length: 9, code: "237" }, // Cameroun: 9 chiffres après +237
    ca: { length: 10, code: "1" }, // Canada: 10 chiffres après +1
    fr: { length: 9, code: "33" }, // France: 9 chiffres après +33
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handlePhoneChange = (value, data) => {
    // On stocke simplement la valeur complète et le code pays
    setFormData((prevState) => ({
      ...prevState,
      fullPhone: value,
      countryCode: data.countryCode.toLowerCase(),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      telephone: "",
    }));
  };

  const validatePhoneNumber = (phone, country) => {
    if (!phoneNumberLengths[country]) {
      return phone.replace(/\D/g, "").length > 0;
    }

    const countryData = phoneNumberLengths[country];
    const numberOnly = phone.replace(/\D/g, "");
    const { length, code } = countryData;

    if (!numberOnly.startsWith(code)) {
      return false;
    }

    const expectedTotalLength = code.length + length;
    if (numberOnly.length !== expectedTotalLength) {
      return false;
    }

    const numberAfterCode = numberOnly.slice(code.length);

    switch (country) {
      case "cm":
        const validPrefixes = ["69", "67", "65", "68", "62"];
        const prefix = numberAfterCode.slice(0, 2);
        return validPrefixes.includes(prefix);

      case "ca":
        const firstDigit = numberAfterCode[0];
        return !["0", "1"].includes(firstDigit);

      case "fr":
        const firstFrDigit = numberAfterCode[0];
        return ["6", "7", "9"].includes(firstFrDigit);

      default:
        return true;
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let validationErrors = {};

    // Vérification des champs vides (sauf fullPhone et telephone)
    Object.entries(formData).forEach(([key, value]) => {
      if (
        key !== "fullPhone" &&
        key !== "telephone" &&
        (!value || value.trim() === "")
      ) {
        validationErrors[key] = "Ce champ est requis.";
      }
    });

    // Validation de l'email
    if (formData.email && !validateEmail(formData.email)) {
      validationErrors.email = "Veuillez entrer une adresse email valide.";
    }

    // Validation du numéro de téléphone
    if (!formData.fullPhone) {
      validationErrors.telephone = "Ce champ est requis.";
    } else if (phoneNumberLengths[formData.countryCode]) {
      const countryData = phoneNumberLengths[formData.countryCode];
      const numberOnly = formData.fullPhone.replace(/\D/g, "");
      const numberAfterCode = numberOnly.slice(countryData.code.length);

      switch (formData.countryCode) {
        case "cm":
          const validPrefixesCM = ["69", "67", "65", "68", "62"];
          const prefixCM = numberAfterCode.slice(0, 2);
          if (!validPrefixesCM.includes(prefixCM)) {
            validationErrors.telephone =
              "Le numéro camerounais doit commencer par 69, 67, 65, 68 ou 62";
          } else if (numberAfterCode.length !== 9) {
            validationErrors.telephone =
              "Le numéro camerounais doit avoir 9 chiffres";
          }
          break;

        case "ca":
          if (numberAfterCode.length !== 10) {
            validationErrors.telephone =
              "Le numéro canadien doit avoir 10 chiffres";
          } else if (["0", "1"].includes(numberAfterCode[0])) {
            validationErrors.telephone =
              "Le numéro canadien ne peut pas commencer par 0 ou 1";
          }
          break;

        case "fr":
          if (numberAfterCode.length !== 9) {
            validationErrors.telephone =
              "Le numéro français doit avoir 9 chiffres";
          } else if (!["6", "7", "9"].includes(numberAfterCode[0])) {
            validationErrors.telephone =
              "Le numéro français mobile doit commencer par 6, 7 ou 9";
          }
          break;

        default:
          if (!validatePhoneNumber(formData.fullPhone, formData.countryCode)) {
            validationErrors.telephone = `Format invalide. Pour ${formData.countryCode.toUpperCase()}, le numéro doit avoir ${
              countryData.length
            } chiffres après +${countryData.code}`;
          }
      }
    }

    // Si des erreurs sont présentes, les afficher et arrêter l'envoi
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    // Mise à jour du numéro de téléphone dans formData avant l'envoi
    const dataToSend = {
      ...formData,
      telephone: formData.fullPhone.replace(/\D/g, ""),
    };

    try {
      await ContactService.sendContactForm(dataToSend);
      toast.success(
        "Votre message a été envoyé ! Vous recevrez un mail de confirmation."
      );
      setFormData({
        nom_utilisateur: "",
        email: "",
        telephone: "",
        subject: "",
        message: "",
        countryCode: "cm",
        fullPhone: "",
      });
      setErrors({});
    } catch (error) {
      if (error.status >= 400 && error.status < 500) {
        toast.error(error.message);
      } else {
        toast.error(
          "Une erreur technique est survenue. Veuillez réessayer plus tard."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="contact-container" id="contact-form">
      <ToastContainer />
      <div className="contact-content">
        <div className="contact-info">
          <h2>Contactez-nous</h2>
          <p>
            Discutons de votre projet et voyons comment nous pouvons collaborer.
          </p>
          <div className="contact-details">
            <div className="contact-item">
              <div className="contact-icon">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div className="contact-text">
                <h3>Adresse</h3>
                <p>Yaoundé, Poste Centrale</p>
              </div>
            </div>
            <div className="contact-item">
              <div className="contact-icon">
                <i className="fas fa-phone-alt"></i>
              </div>
              <div className="contact-text">
                <h3>Téléphone</h3>
                <p>+237 678 463 251</p>
                <p>+237 689 195 316</p>
              </div>
            </div>
            <div className="contact-item">
              <div className="contact-icon">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="contact-text">
                <h3>Email</h3>
                <p>contact@stelotechconsulting.com</p>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-form-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="nom_utilisateur"
                value={formData.nom_utilisateur}
                onChange={handleChange}
                placeholder="Votre nom"
                className={`form-input ${
                  errors.nom_utilisateur ? "error" : ""
                }`}
              />
              {errors.nom_utilisateur && (
                <span className="error-message">{errors.nom_utilisateur}</span>
              )}
              <span className="focus-border"></span>
            </div>

            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Votre email"
                className={`form-input ${errors.email ? "error" : ""}`}
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}
              <span className="focus-border"></span>
            </div>

            <div className="form-group">
              <PhoneInput
                country={formData.countryCode}
                value={formData.telephone}
                onChange={handlePhoneChange}
                preferredCountries={["cm", "ca", "fr"]}
                enableSearch={true}
                className={`form-input ${
                  errors.telephone ? "error" : ""
                } custom-phone-input`}
                placeholder="Votre Numéro de téléphone"
                countryCodeEditable={false}
              />
              {errors.telephone && (
                <span className="error-message">{errors.telephone}</span>
              )}
              <span className="focus-border"></span>
            </div>

            <div className="form-group">
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Sujet"
                className={`form-input ${errors.subject ? "error" : ""}`}
              />
              {errors.subject && (
                <span className="error-message">{errors.subject}</span>
              )}
              <span className="focus-border"></span>
            </div>

            <div className="form-group">
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Votre message"
                className={`form-input ${errors.message ? "error" : ""}`}
                rows="5"
              ></textarea>
              {errors.message && (
                <span className="error-message">{errors.message}</span>
              )}
              <span className="focus-border"></span>
            </div>

            <button
              type="submit"
              className={`submit-button ${isLoading ? "submitted" : ""}`}
              disabled={isLoading}
            >
              {isLoading ? <LoadingSpinner /> : "Envoyer le message"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
