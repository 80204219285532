import React from "react";
import HeroSection from "./components/Hero2";
import Services from "./components/Services";
import Projets from "./components/Projets";
import Contact from "./components/Contact";
import Testimonials from "./components/Testimonials";

const Homepage = () => {
  return (
    <div>
      <HeroSection />
      {<Services />}
      <Projets />
      <Testimonials />
      <Contact />
    </div>
  );
};

export default Homepage;
