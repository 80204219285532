import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactComponent from "../Homepage/components/Contact"; // Garder le composant ContactComponent
import "./Styles/ContactPage.css";
import FAQComponent from "../FAQ/FAQ";

const Contact = () => {
  /*  const [alert, setAlert] = useState({
    show: false,
    variant: "success",
    message: "",
  }); */

  //const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      {/* Alerte placée en haut de la page */}
      {/*  {alert.show && (
        <Alert
          variant={alert.variant}
          className="position-fixed w-100 text-center"
          style={{ top: "56px", zIndex: 1000 }}
          dismissible
          onClose={() => setAlert((prev) => ({ ...prev, show: false }))}
        >
          {alert.message}
        </Alert>
      )} */}

      <div className="contact-page">
        {/* <div className="contact-container-image">
          <div className="contact-hero">
            <Container>
              <h1 className="contact-title">Contactez-nous</h1>
              <p className="contact-subtitle">
                Notre équipe est là pour vous aider et répondre à toutes vos
                questions
              </p>
            </Container>
          </div>
        </div> */}
        <FAQComponent />

        <Container className="contact-container">
          <ContactComponent />

          <Row className="contact-map-section">
            <Col md={12}>
              <div className="contact-map-wrapper">
                <iframe
                  src="https://www.google.com/maps?q=3.860283,11.521137&z=15&output=embed"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="map"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Contact;
