import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      {/* Section supérieure pour les liens*/}
      <div className="footer-top py-4 text-white">
        <Container>
          <Row>
            <Col md={4}>
              <h5>Support</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="contact">FAQ</a>
                </li>
                <li>
                  <a href="contact#contact-form">Nous contacter</a>
                </li>
                <li>
                  <a href="contact#contact-form">Assistance technique</a>
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Liens Utiles</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="services/web-development">Nos services</a>
                </li>
                <li>
                  <a href="/solutions/finance">Nos solutions</a>
                </li>
                <li>
                  <a href="apropos">A propos de nous</a>
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Suivez-nous</h5>
              <ul className="social-icons list-unstyled">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100090427166643"
                    className="social-icon facebook"
                  >
                    <FaFacebookF size={24} />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com" className="social-icon twitter">
                    <FaTwitter size={24} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com"
                    className="social-icon linkedin"
                  >
                    <FaLinkedinIn size={24} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/237678463251"
                    className="social-icon whatsapp"
                  >
                    <FaWhatsapp size={24} />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Section inférieure pour les droits d'auteur */}
      <div className="footer-bottom  text-white text-center py-3">
        <Container>
          <p className="mb-0">
            © {new Date().getFullYear()} Stelotech Consulting | Tous droits
            réservés.
          </p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
