import React from "react";
import "../Styles/SolutionDetail.css";

const SolutionDetail = ({
  title,
  category,
  description,
  features,
  benefits,
  targetAudience,
  modules,
  customization,
  imageBg,
}) => {
  return (
    <div className="solution-detail" style={{ "--solution-bg": imageBg }}>
      <div className="solution-header">
        <div className="header-content">
          <span className="solution-category">{category}</span>
          <h1 className="solution-title">{title}</h1>
          <p className="solution-description">{description}</p>
        </div>
      </div>

      <div className="solution-content">
        <section className="features-section">
          <h2>Fonctionnalités Principales</h2>
          <div className="features-grid">
            {features.map((feature, index) => (
              <div className="feature-card" key={index}>
                <div className="feature-icon">{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="benefits-section">
          <h2>Avantages</h2>
          <div className="benefits-list">
            {benefits.map((benefit, index) => (
              <div className="benefit-item" key={index}>
                <span className="benefit-checkmark">✓</span>
                <p>{benefit}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="target-section">
          <h2>Pour Qui ?</h2>
          <div className="target-grid">
            {targetAudience.map((target, index) => (
              <div className="target-card" key={index}>
                <div className="target-icon">{target.icon}</div>
                <h3>{target.title}</h3>
                <p>{target.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="modules-section">
          <h2>Modules Disponibles</h2>
          <div className="modules-grid">
            {modules.map((module, index) => (
              <div className="module-card" key={index}>
                <h3>{module.title}</h3>
                <ul>
                  {module.features.map((feature, featureIndex) => (
                    <li key={featureIndex}>{feature}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        <section className="customization-section">
          <h2>Personnalisation</h2>
          <div className="customization-content">
            <p>{customization.description}</p>
            <div className="customization-options">
              {customization.options.map((option, index) => (
                <div className="option-item" key={index}>
                  <span className="option-icon">{option.icon}</span>
                  <span>{option.text}</span>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SolutionDetail;
