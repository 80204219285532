// FinanceSolution.jsx
import React from "react";
import SolutionDetail from "./Components/SolutionDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBillTransfer,
  faMobileScreen,
  faChartLine,
  faShieldHalved,
  faCreditCard,
  faUsers,
  faBuilding,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import FinanceImage from "../../assets/img/graphique-finance-entreprise-du.webp";

const FinanceSolution = () => {
  const solutionData = {
    title: "Solutions Financières",
    category: "Finance",
    description:
      "Des solutions innovantes pour moderniser et sécuriser vos opérations financières, adaptées aux besoins des institutions financières et des entreprises.",
    imageBg: `url(${FinanceImage})`,
    features: [
      {
        icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
        title: "Transfert d'Argent",
        description:
          "Système de transfert national et international avec suivi en temps réel",
      },
      {
        icon: <FontAwesomeIcon icon={faMobileScreen} />,
        title: "Mobile Banking",
        description:
          "Application mobile complète pour la gestion des comptes et transactions",
      },
      {
        icon: <FontAwesomeIcon icon={faChartLine} />,
        title: "Analyse Financière",
        description:
          "Tableaux de bord et rapports détaillés pour le suivi des performances",
      },
      {
        icon: <FontAwesomeIcon icon={faShieldHalved} />,
        title: "Sécurité Avancée",
        description:
          "Protocoles de sécurité de pointe pour la protection des données",
      },
    ],
    benefits: [
      "Réduction des coûts opérationnels de 40%",
      "Traitement instantané des transactions",
      "Conformité aux normes réglementaires",
      "Support technique 24/7",
      "Mises à jour régulières de sécurité",
      "Intégration avec les systèmes existants",
    ],
    targetAudience: [
      {
        icon: <FontAwesomeIcon icon={faBuilding} />,
        title: "Banques",
        description:
          "Pour les institutions bancaires cherchant à moderniser leurs services",
      },
      {
        icon: <FontAwesomeIcon icon={faCreditCard} />,
        title: "IMF",
        description: "Solutions adaptées aux institutions de microfinance",
      },
      {
        icon: <FontAwesomeIcon icon={faStore} />,
        title: "Commerces",
        description:
          "Pour les entreprises nécessitant des solutions de paiement",
      },
    ],
    modules: [
      {
        title: "Gestion des Transactions",
        features: [
          "Transferts nationaux et internationaux",
          "Paiements marchands",
          "Gestion des bénéficiaires",
          "Historique des transactions",
          "Rapports en temps réel",
        ],
      },
      {
        title: "Administration",
        features: [
          "Gestion des utilisateurs",
          "Paramétrage des commissions",
          "Définition des plafonds",
          "Gestion des droits d'accès",
          "Audit trail complet",
        ],
      },
      {
        title: "Reporting",
        features: [
          "Tableaux de bord personnalisables",
          "Rapports réglementaires",
          "Analyses statistiques",
          "Export multi-format",
          "Reporting automatisé",
        ],
      },
    ],
    customization: {
      description:
        "Notre solution financière s'adapte parfaitement à vos besoins spécifiques et à votre structure organisationnelle.",
      options: [
        {
          icon: <FontAwesomeIcon icon={faUsers} />,
          text: "Interface personnalisable",
        },
        {
          icon: <FontAwesomeIcon icon={faChartLine} />,
          text: "Rapports sur mesure",
        },
        {
          icon: <FontAwesomeIcon icon={faShieldHalved} />,
          text: "Paramètres de sécurité ajustables",
        },
      ],
    },
  };

  return <SolutionDetail {...solutionData} />;
};

export default FinanceSolution;

/* import SolutionDetail from "./Components/SolutionDetail";

const FinanceSolution = () => {
  const solutionData = {
    title: "Solutions Financières",
    category: "Finance",
    description: "Solutions complètes pour la gestion financière et bancaire",
    imageBg: "linear-gradient(45deg, #2c5364, #203a43)",
    features: [
      {
        icon: "💳",
        title: "Paiement Mobile",
        description: "Système de paiement sécurisé via mobile",
      },
      {
        icon: "🔄",
        title: "Transfert d'Argent",
        description: "Transferts nationaux et internationaux",
      },
      // Autres fonctionnalités...
    ],
    benefits: [
      "Sécurité renforcée des transactions",
      "Interface utilisateur intuitive",
      "Support technique 24/7",
      // Autres avantages...
    ],
    targetAudience: [
      {
        icon: "🏦",
        title: "Banques",
        description: "Pour les institutions financières",
      },
      // Autres cibles...
    ],
    modules: [
      {
        title: "Gestion des Transactions",
        features: [
          "Suivi en temps réel",
          "Historique détaillé",
          "Rapports automatisés",
        ],
      },
      // Autres modules...
    ],
    customization: {
      description: "Solution entièrement personnalisable selon vos besoins",
      options: [
        {
          icon: "⚙️",
          text: "Paramétrage sur mesure",
        },
        // Autres options...
      ],
    },
  };

  return <SolutionDetail {...solutionData} />;
};

export default FinanceSolution;
 */
