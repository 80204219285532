import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/Projets.css";

const Projets = () => {
  const [activeFilter, setActiveFilter] = useState("all");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll(".project-card").forEach((card) => {
      observer.observe(card);
    });

    return () => observer.disconnect();
  }, [activeFilter]);

  const projects = [
    {
      id: 1,
      title: "Applications Financières",
      category: "finance",
      description:
        "Solutions de transfert d'argent, paiement mobile, gestion financière et bancaire",
      stats: {
        type: "Web & Mobile",
        secteur: "Finance",
      },
      imageBg: "linear-gradient(45deg, #2c5364, #203a43)",
      path: "/solutions/finance", // Ajout du chemin
    },
    {
      id: 2,
      title: "Applications Médicales",
      category: "medical",
      description:
        "Gestion de cabinet médical, clinique et hôpital, dossiers patients",
      stats: {
        type: "Web & Mobile",
        secteur: "Santé",
      },
      imageBg: "linear-gradient(45deg, #0f4c75, #3282b8)",
      path: "/solutions/medical", // Ajout du chemin
    },
    {
      id: 3,
      title: "Applications d'Établissement",
      category: "education",
      description: "Gestion scolaire, universitaire et centre de formation",
      stats: {
        type: "Web",
        secteur: "Éducation",
      },
      imageBg: "linear-gradient(45deg, #654ea3, #eaafc8)",
      path: "/solutions/education", // Ajout du chemin
    },
    {
      id: 4,
      title: "Applications de Gestion",
      category: "business",
      description:
        "Gestion de stock, pressing, commerce, facturation et comptabilité",
      stats: {
        type: "Web & Mobile",
        secteur: "Commerce",
      },
      imageBg: "linear-gradient(45deg, #373b44, #4286f4)",
      path: "/solutions/business", // Ajout du chemin
    },
  ];

  const filters = [
    { id: "all", label: "Tous" },
    { id: "finance", label: "Finance" },
    { id: "medical", label: "Médical" },
    { id: "education", label: "Éducation" },
    { id: "business", label: "Gestion" },
  ];

  const handleFilterClick = (filterId) => {
    setActiveFilter(filterId);
  };

  const filteredProjects = projects.filter((project) =>
    activeFilter === "all" ? true : project.category === activeFilter
  );

  return (
    <div className="portfolio-container" id="projets">
      <div className="portfolio-header">
        <h2 className="portfolio-title">Nos Solutions</h2>
        <div className="portfolio-filters">
          {filters.map((filter) => (
            <button
              key={filter.id}
              className={`filter-btn ${
                activeFilter === filter.id ? "active" : ""
              }`}
              onClick={() => handleFilterClick(filter.id)}
            >
              {filter.label}
            </button>
          ))}
        </div>
      </div>

      <div className="projects-grid">
        {filteredProjects.map((project) => (
          <div
            className="project-card"
            key={project.id}
            style={{ "--project-bg": project.imageBg }}
          >
            <div className="project-content">
              <div className="project-header">
                <h3>{project.title}</h3>
                <div className="project-category">{project.stats.secteur}</div>
              </div>

              <p className="project-description">{project.description}</p>

              <div className="project-stats">
                <div className="stat">
                  <span className="stat-label">Type</span>
                  <span className="stat-value">{project.stats.type}</span>
                </div>
              </div>

              <div className="project-actions">
                <Link to={project.path} className="view-project-btn">
                  En savoir plus
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projets;
